import { CountriesType } from '@/types/order';

export const countriesChData: CountriesType[] = [
  {
    id: 41,
    code: 'CN',
    name: 'China',
    number: '86',
    is_shipping: true,
    shipping_cost_fedex: '24.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '7.00'
  },
  {
    id: 82,
    code: 'HK',
    name: 'Hong Kong',
    number: '852',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '6.00'
  },
  {
    id: 167,
    code: 'TW',
    name: 'Taiwan',
    number: '886',
    is_shipping: true,
    shipping_cost_fedex: '23.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '7.00'
  },
  {
    id: 104,
    code: 'MO',
    name: 'Macao',
    number: '853',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 1,
    code: 'KR',
    name: 'South Korea',
    number: '82',
    is_shipping: true,
    shipping_cost_fedex: '4.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 2,
    code: 'AL',
    name: 'Albania',
    number: '355',
    is_shipping: true,
    shipping_cost_fedex: '30.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 3,
    code: 'DZ',
    name: 'Algeria',
    number: '213',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 4,
    code: 'WS',
    name: 'American Samoa',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 5,
    code: 'AD',
    name: 'Andorra',
    number: '376',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 6,
    code: 'AO',
    name: 'Angola',
    number: '244',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 7,
    code: 'AI',
    name: 'Anguilla',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 8,
    code: 'AG',
    name: 'Antigua and Barbuda',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 9,
    code: 'AR',
    name: 'Argentina',
    number: '54',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 10,
    code: 'AM',
    name: 'Armenia',
    number: '374',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 11,
    code: 'AW',
    name: 'Aruba',
    number: '297',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 12,
    code: 'AU',
    name: 'Australia',
    number: '61',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '9.00'
  },
  {
    id: 13,
    code: 'AT',
    name: 'Austria',
    number: '43',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 14,
    code: 'AZ',
    name: 'Azerbaijan',
    number: '994',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 15,
    code: 'BS',
    name: 'Bahamas',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 16,
    code: 'BH',
    name: 'Bahrain',
    number: '973',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 17,
    code: 'BD',
    name: 'Bangladesh',
    number: '880',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 18,
    code: 'BB',
    name: 'Barbados',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 19,
    code: 'BY',
    name: 'Belarus',
    number: '375',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 20,
    code: 'BE',
    name: 'Belgium',
    number: '32',
    is_shipping: true,
    shipping_cost_fedex: '26.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 21,
    code: 'BZ',
    name: 'Belize',
    number: '501',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 22,
    code: 'BJ',
    name: 'Benin',
    number: '229',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 23,
    code: 'BM',
    name: 'Bermuda',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 24,
    code: 'BT',
    name: 'Bhutan',
    number: '975',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 25,
    code: 'BO',
    name: 'Bolivia',
    number: '591',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 26,
    code: 'BW',
    name: 'Botswana',
    number: '267',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 27,
    code: 'BR',
    name: 'Brazil',
    number: '55',
    is_shipping: true,
    shipping_cost_fedex: '100.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '10.00'
  },
  {
    id: 29,
    code: 'BN',
    name: 'Brunei Darussalam',
    number: '673',
    is_shipping: true,
    shipping_cost_fedex: '50.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 30,
    code: 'BG',
    name: 'Bulgaria',
    number: '359',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 31,
    code: 'BF',
    name: 'Burkina Faso',
    number: '226',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 32,
    code: 'BI',
    name: 'Burundi',
    number: '257',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 36,
    code: 'CV',
    name: 'Cabo Verde',
    number: '238',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 33,
    code: 'KH',
    name: 'Cambodia',
    number: '855',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 34,
    code: 'CM',
    name: 'Cameroon',
    number: '237',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 35,
    code: 'CA',
    name: 'Canada',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '30.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '11.00'
  },
  {
    id: 37,
    code: 'KY',
    name: 'Cayman Islands',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 38,
    code: 'CF',
    name: 'Central African Republic',
    number: '236',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 39,
    code: 'TD',
    name: 'Chad',
    number: '235',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 40,
    code: 'CL',
    name: 'Chile',
    number: '56',
    is_shipping: true,
    shipping_cost_fedex: '52.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 42,
    code: 'CO',
    name: 'Colombia',
    number: '57',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 43,
    code: 'CG',
    name: 'Congo',
    number: '242',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 45,
    code: 'CK',
    name: 'Cook Islands',
    number: '682',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 46,
    code: 'CR',
    name: 'Costa Rica',
    number: '506',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 90,
    code: 'CI',
    name: "Côte d'Ivoire",
    number: '225',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 47,
    code: 'HR',
    name: 'Croatia',
    number: '385',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 48,
    code: 'CY',
    name: 'Cyprus',
    number: '357',
    is_shipping: true,
    shipping_cost_fedex: '29.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 49,
    code: 'CZ',
    name: 'Czechia',
    number: '420',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 44,
    code: 'CD',
    name: 'Democratic Republic of the Congo',
    number: '180',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 50,
    code: 'DK',
    name: 'Denmark',
    number: '45',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 51,
    code: 'DJ',
    name: 'Djibouti',
    number: '253',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 52,
    code: 'DM',
    name: 'Dominica',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 53,
    code: 'EC',
    name: 'Ecuador',
    number: '593',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 54,
    code: 'EG',
    name: 'Egypt',
    number: '20',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 55,
    code: 'SV',
    name: 'El Salvador',
    number: '503',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 56,
    code: 'GQ',
    name: 'Equatorial Guinea',
    number: '240',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 57,
    code: 'ER',
    name: 'Eritrea',
    number: '291',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 58,
    code: 'EE',
    name: 'Estonia',
    number: '372',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 163,
    code: 'SZ',
    name: 'Eswatini',
    number: '268',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 59,
    code: 'ET',
    name: 'Ethiopia',
    number: '251',
    is_shipping: true,
    shipping_cost_fedex: '26.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 60,
    code: 'FO',
    name: 'Faroe Islands',
    number: '298',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 61,
    code: 'FJ',
    name: 'Fiji',
    number: '679',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 62,
    code: 'FI',
    name: 'Finland',
    number: '358',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 63,
    code: 'FR',
    name: 'France',
    number: '33',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '10.00'
  },
  {
    id: 64,
    code: 'GF',
    name: 'French Guiana',
    number: '594',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 65,
    code: 'GA',
    name: 'Gabon',
    number: '241',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 66,
    code: 'GM',
    name: 'Gambia',
    number: '220',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 67,
    code: 'GE',
    name: 'Georgia',
    number: '995',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 68,
    code: 'DE',
    name: 'Germany',
    number: '49',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '9.00'
  },
  {
    id: 69,
    code: 'GH',
    name: 'Ghana',
    number: '233',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 70,
    code: 'GI',
    name: 'Gibraltar',
    number: '350',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 71,
    code: 'GR',
    name: 'Greece',
    number: '30',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 72,
    code: 'GL',
    name: 'Greenland',
    number: '299',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 73,
    code: 'GD',
    name: 'Grenada',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 74,
    code: 'GP',
    name: 'Guadeloupe',
    number: '590',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 75,
    code: 'GU',
    name: 'Guam',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 76,
    code: 'GT',
    name: 'Guatemala',
    number: '502',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 77,
    code: 'GN',
    name: 'Guinea',
    number: '224',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 78,
    code: 'GW',
    name: 'Guinea-Bissau',
    number: '245',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 79,
    code: 'GY',
    name: 'Guyana',
    number: '592',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 80,
    code: 'HT',
    name: 'Haiti',
    number: '509',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 185,
    code: 'VA',
    name: 'Holy See',
    number: '39',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 81,
    code: 'HN',
    name: 'Honduras',
    number: '504',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 83,
    code: 'HU',
    name: 'Hungary',
    number: '36',
    is_shipping: true,
    shipping_cost_fedex: '22.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 84,
    code: 'IS',
    name: 'Iceland',
    number: '354',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 85,
    code: 'IN',
    name: 'India',
    number: '91',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 86,
    code: 'ID',
    name: 'Indonesia',
    number: '62',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '7.00'
  },
  {
    id: 87,
    code: 'IE',
    name: 'Ireland',
    number: '353',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 88,
    code: 'IL',
    name: 'Israel',
    number: '972',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 89,
    code: 'IT',
    name: 'Italy',
    number: '39',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 91,
    code: 'JM',
    name: 'Jamaica',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 92,
    code: 'JP',
    name: 'Japan',
    number: '81',
    is_shipping: true,
    shipping_cost_fedex: '24.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '7.00'
  },
  {
    id: 93,
    code: 'JO',
    name: 'Jordan',
    number: '962',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 94,
    code: 'KZ',
    name: 'Kazakhstan',
    number: '7',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 95,
    code: 'KE',
    name: 'Kenya',
    number: '254',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 96,
    code: 'KW',
    name: 'Kuwait',
    number: '965',
    is_shipping: true,
    shipping_cost_fedex: '35.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 97,
    code: 'KG',
    name: 'Kyrgyzstan',
    number: '996',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 98,
    code: 'LV',
    name: 'Latvia',
    number: '371',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 99,
    code: 'LB',
    name: 'Lebanon',
    number: '961',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 100,
    code: 'LS',
    name: 'Lesotho',
    number: '266',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 101,
    code: 'LI',
    name: 'Liechtenstein',
    number: '423',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 102,
    code: 'LT',
    name: 'Lithuania',
    number: '370',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 103,
    code: 'LU',
    name: 'Luxembourg',
    number: '352',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 106,
    code: 'MG',
    name: 'Madagascar',
    number: '261',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 107,
    code: 'MW',
    name: 'Malawi',
    number: '265',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 108,
    code: 'MY',
    name: 'Malaysia',
    number: '60',
    is_shipping: true,
    shipping_cost_fedex: '23.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '7.00'
  },
  {
    id: 109,
    code: 'MV',
    name: 'Maldives',
    number: '960',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 110,
    code: 'ML',
    name: 'Mali',
    number: '223',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 111,
    code: 'MT',
    name: 'Malta',
    number: '356',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 112,
    code: 'MH',
    name: 'Marshall Islands',
    number: '692',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 113,
    code: 'MQ',
    name: 'Martinique',
    number: '596',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 114,
    code: 'MR',
    name: 'Mauritania',
    number: '222',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 115,
    code: 'MU',
    name: 'Mauritius',
    number: '230',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 116,
    code: 'MX',
    name: 'Mexico',
    number: '52',
    is_shipping: true,
    shipping_cost_fedex: '29.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 117,
    code: 'FM',
    name: 'Micronesia, Federated States of',
    number: '691',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 118,
    code: 'MD',
    name: 'Moldova, Republic of',
    number: '373',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 119,
    code: 'MC',
    name: 'Monaco',
    number: '377',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 120,
    code: 'MN',
    name: 'Mongolia',
    number: '976',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 121,
    code: 'MS',
    name: 'Montserrat',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 122,
    code: 'MA',
    name: 'Morocco',
    number: '212',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 123,
    code: 'MZ',
    name: 'Mozambique',
    number: '258',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 124,
    code: 'NA',
    name: 'Namibia',
    number: '264',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 125,
    code: 'NP',
    name: 'Nepal',
    number: '977',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 126,
    code: 'NL',
    name: 'Netherlands',
    number: '31',
    is_shipping: true,
    shipping_cost_fedex: '29.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 127,
    code: 'NC',
    name: 'New Caledonia',
    number: '687',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 128,
    code: 'NZ',
    name: 'New Zealand',
    number: '64',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '9.00'
  },
  {
    id: 129,
    code: 'NI',
    name: 'Nicaragua',
    number: '505',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 130,
    code: 'NE',
    name: 'Niger',
    number: '227',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 131,
    code: 'NG',
    name: 'Nigeria',
    number: '234',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 105,
    code: 'MK',
    name: 'North Macedonia',
    number: '389',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 132,
    code: 'NO',
    name: 'Norway',
    number: '47',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 133,
    code: 'OM',
    name: 'Oman',
    number: '968',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 134,
    code: 'PK',
    name: 'Pakistan',
    number: '92',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 135,
    code: 'PW',
    name: 'Palau',
    number: '680',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 136,
    code: 'PA',
    name: 'Panama',
    number: '507',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 137,
    code: 'PG',
    name: 'Papua New Guinea',
    number: '675',
    is_shipping: true,
    shipping_cost_fedex: '29.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 138,
    code: 'PY',
    name: 'Paraguay',
    number: '595',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 139,
    code: 'PE',
    name: 'Peru',
    number: '51',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 140,
    code: 'PH',
    name: 'Philippines',
    number: '63',
    is_shipping: true,
    shipping_cost_fedex: '16.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 141,
    code: 'PL',
    name: 'Poland',
    number: '48',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 142,
    code: 'PT',
    name: 'Portugal',
    number: '351',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 143,
    code: 'QA',
    name: 'Qatar',
    number: '974',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 144,
    code: 'RE',
    name: 'Réunion',
    number: '262',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 145,
    code: 'RO',
    name: 'Romania',
    number: '40',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 146,
    code: 'RU',
    name: 'Russian Federation',
    number: '7',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 147,
    code: 'RW',
    name: 'Rwanda',
    number: '250',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 159,
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 160,
    code: 'LC',
    name: 'Saint Lucia',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 161,
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 148,
    code: 'SM',
    name: 'San Marino',
    number: '378',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 149,
    code: 'SA',
    name: 'Saudi Arabia',
    number: '966',
    is_shipping: true,
    shipping_cost_fedex: '35.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 150,
    code: 'SN',
    name: 'Senegal',
    number: '221',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 191,
    code: 'RS',
    name: 'Serbia',
    number: '381',
    is_shipping: true,
    shipping_cost_fedex: '30.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 151,
    code: 'SC',
    name: 'Seychelles',
    number: '248',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 152,
    code: 'SL',
    name: 'Sierra Leone',
    number: '232',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 153,
    code: 'SG',
    name: 'Singapore',
    number: '65',
    is_shipping: true,
    shipping_cost_fedex: '20.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '7.00'
  },
  {
    id: 154,
    code: 'SK',
    name: 'Slovakia',
    number: '421',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 155,
    code: 'SI',
    name: 'Slovenia',
    number: '386',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 156,
    code: 'ZA',
    name: 'South Africa',
    number: '27',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 157,
    code: 'ES',
    name: 'Spain',
    number: '34',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '10.00'
  },
  {
    id: 158,
    code: 'LK',
    name: 'Sri Lanka',
    number: '94',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 162,
    code: 'SR',
    name: 'Suriname',
    number: '597',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 164,
    code: 'SE',
    name: 'Sweden',
    number: '46',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 165,
    code: 'CH',
    name: 'Switzerland',
    number: '41',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 166,
    code: 'SY',
    name: 'Syrian Arab Republic',
    number: '963',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 168,
    code: 'TZ',
    name: 'Tanzania, United Republic of',
    number: '255',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 169,
    code: 'TH',
    name: 'Thailand',
    number: '66',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '7.00'
  },
  {
    id: 170,
    code: 'TG',
    name: 'Togo',
    number: '228',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 171,
    code: 'TT',
    name: 'Trinidad and Tobago',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 172,
    code: 'TN',
    name: 'Tunisia',
    number: '216',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 173,
    code: 'TR',
    name: 'Turkey',
    number: '90',
    is_shipping: true,
    shipping_cost_fedex: '32.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 174,
    code: 'TM',
    name: 'Turkmenistan',
    number: '993',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 175,
    code: 'TC',
    name: 'Turks and Caicos Islands',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 179,
    code: 'UG',
    name: 'Uganda',
    number: '256',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 180,
    code: 'UA',
    name: 'Ukraine',
    number: '380',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 176,
    code: 'AE',
    name: 'United Arab Emirates',
    number: '971',
    is_shipping: true,
    shipping_cost_fedex: '35.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 181,
    code: 'GB',
    name: 'United Kingdom',
    number: '44',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '9.00'
  },
  {
    id: 178,
    code: 'US',
    name: 'United States',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '30.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '12.00'
  },
  {
    id: 193,
    code: 'GU',
    name: 'United States (Guam)',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '30.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 192,
    code: 'PR',
    name: 'United States (Puerto Rico)',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '30.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 182,
    code: 'UY',
    name: 'Uruguay',
    number: '598',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 183,
    code: 'UZ',
    name: 'Uzbekistan',
    number: '998',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 184,
    code: 'VU',
    name: 'Vanuatu',
    number: '678',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 186,
    code: 'VE',
    name: 'Venezuela, Bolivarian Republic of',
    number: '58',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 187,
    code: 'VN',
    name: 'Viet Nam',
    number: '84',
    is_shipping: true,
    shipping_cost_fedex: '28.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: '6.00'
  },
  {
    id: 28,
    code: 'VG',
    name: 'Virgin Islands (British)',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 177,
    code: 'VI',
    name: 'Virgin Islands, (U.S.)',
    number: '1',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 188,
    code: 'YE',
    name: 'Yemen',
    number: '967',
    is_shipping: true,
    shipping_cost_fedex: '31.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 189,
    code: 'ZM',
    name: 'Zambia',
    number: '260',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  },
  {
    id: 190,
    code: 'ZW',
    name: 'Zimbabwe',
    number: '263',
    is_shipping: true,
    shipping_cost_fedex: '45.00',
    is_kpacket_event: false,
    kpacket_event_price: null,
    shipping_cost_kpacket: null
  }
];
