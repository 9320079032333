import { format, pickupDate } from '@/utils/pickupDate';
import { useEffect, useState } from 'react';
export interface pickupStateType {
  nextDay: number;
  nextDayTwo?: number;
  pickupCase: number;
}

export const usePickupTime = () => {
  const { currentDate, hour, minute, nextWeekMonday, today, nextDay, getPickupCase } = pickupDate();
  const [pickupTime, setPickupTime] = useState('');
  const [pickupTimeTwo, setPickupTimeTwo] = useState('');
  const [pickupHour, setPickupHour] = useState<7 | 12 | 2>(12);
  const [pickupCase, setPickupCase] = useState(getPickupCase());
  const isAfterFourHour = hour >= 16; //4시 이후
  const isBetweenTime = hour === 15 && minute >= 30; //3시반 ~ 4시

  useEffect(() => {
    setPickupTimeTwo('');
    setPickupCase(getPickupCase());

    //크리스마스
    if (today === '2024/12/24') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(12);
        return;
      }
      if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today === '2024/12/25') {
      setPickupTime(nextDay);
      setPickupHour(12);
      return;
    }

    //1.1
    if (today === '2025/12/31') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(12);
        return;
      }
      if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(2, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today === '2025/01/01') {
      setPickupTime(nextDay);
      setPickupHour(12);
      return;
    }

    //1.28 ~ 1.30
    if (today === '2025/01/27') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(5, 'days').format(format));
        setPickupHour(12);
        return;
      } else if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(5, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today > '2025/01/27' && today < '2025/01/31') {
      setPickupTime('2025/01/31');
      setPickupHour(12);
      return;
    }

    //3.3
    if (today === '2025/02/28') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(3, 'days').format(format));
        setPickupHour(12);
        return;
      } else if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(3, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today > '2025/02/28' && today < '2025/03/03') {
      setPickupTime('2025/03/03');
      setPickupHour(12);
      return;
    }

    //5.5 ~ 5.6
    if (today === '2025/05/02') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(3, 'days').format(format));
        setPickupHour(12);
        return;
      } else if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(3, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today > '2025/05/02' && today < '2025/05/07') {
      setPickupTime('2025/05/07');
      setPickupHour(12);
      return;
    }

    //6.6
    if (today === '2025/06/05') {
      if (isAfterFourHour) {
        setPickupTime(currentDate.clone().add(4, 'days').format(format));
        setPickupHour(12);
        return;
      } else if (isBetweenTime) {
        setPickupTime(today);
        setPickupTimeTwo(currentDate.clone().add(4, 'days').format(format));
        setPickupHour(7);
        return;
      } else {
        setPickupTime(today);
        setPickupHour(7);
        return;
      }
    } else if (today > '2025/06/05' && today < '2025/06/09') {
      setPickupTime('2025/06/09');
      setPickupHour(12);
      return;
    }

    switch (pickupCase) {
      case 1:
        if (isAfterFourHour) {
          setPickupTime(nextDay);
          break;
        }
        if (isBetweenTime) {
          setPickupTime(today);
          setPickupTimeTwo(nextDay);
          break;
        } else {
          setPickupTime(today);
          break;
        }
      case 2:
        if (isAfterFourHour) {
          setPickupTime(nextWeekMonday);
          break;
        }
        if (isBetweenTime) {
          setPickupTime(today);
          setPickupTimeTwo(nextWeekMonday);
          break;
        } else {
          setPickupTime(today);
          break;
        }
      case 3:
        setPickupTime(nextWeekMonday);
    }

    setPickupHour(pickupCase === 3 || isAfterFourHour ? 12 : 7);
  }, [currentDate]);

  return { pickupTime, pickupTimeTwo, pickupCase, pickupHour };
};
