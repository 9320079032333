import { InputHTMLAttributes, useEffect, useState } from 'react';
import './NationSelect.scss';
import { CountriesType } from '@/types/order';
import { FormattedMessage } from 'react-intl';

interface NationSelectProps extends InputHTMLAttributes<HTMLInputElement> {
  menuClassName?: string;
  menus: CountriesType[];
  onMenuSelect?: (code: string, codeNumber: string) => void;
  defaultValue?: string;
  inputContainerClassName?: string;
  menuValue?: string;
}

export const NationSelect = ({ onMenuSelect, menuClassName, menus, menuValue }: NationSelectProps) => {
  const [isOptionVisible, setIsOptionVisible] = useState(false);
  const fistValue = menus?.filter((i) => i.code === menuValue);
  const [clickOption, setClickOption] = useState('');
  const handleClick = (name: string, codeNumber: string, code: string) => {
    setClickOption(`${name} +${codeNumber}`);
    onMenuSelect?.(code, codeNumber);
  };
  const [searchMenus, setSearchMenus] = useState(menus);
  const [searchValue, setSearchValue] = useState<string>();

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    setSearchMenus(menus.filter((menuItem) => menuItem.name.toLocaleUpperCase().includes(value.toLocaleUpperCase())));
  };

  useEffect(() => {
    if (menuValue) setClickOption(`${fistValue[0].name} +${fistValue[0].number}`);
  }, [menuValue]);

  return (
    <>
      <div
        className="input-menu-wrap"
        onClick={() => {
          setIsOptionVisible((pev) => !pev);
          handleSearchValue('');
        }}
      >
        <div className={`input-menu${menuClassName ? ` ${menuClassName}` : ''}`}>
          <span className="option-value">{menuValue ? clickOption : 'Select nation'}</span>
        </div>
        <img
          src="/icons/arrow-down.svg"
          alt="arrow icon"
          className="input-menu-arrow-down"
          style={{ transform: isOptionVisible ? 'rotate(180deg)' : 'rotate(0)' }}
        />
      </div>
      {isOptionVisible && (
        <div className="input-menu-wrapper">
          <div className="select-nation-search-input-wrapper">
            <div className="select-nation-search-input-wrapper-left">
              <img src="/icons/search.svg" alt="search-icon" width={24} height={24} />
              <input
                id="select-name"
                type="text"
                placeholder="Search"
                value={searchValue}
                onChange={({ target }) => handleSearchValue(target.value)}
                className="select-nation-input"
              />
            </div>
            {searchValue && <img src="/icons/huge.svg" alt="huge-svg" onClick={() => handleSearchValue('')} />}
          </div>
          <ul className="input-menu-ul">
            {searchMenus.map((item) => (
              <>
                <li
                  key={item.id}
                  className="input-menu-option"
                  onClick={() => {
                    handleClick(item.name, item.number, item.code);
                    setIsOptionVisible((pev) => !pev);
                  }}
                >
                  {`${item.name} +${item.number}`}
                </li>
              </>
            ))}
            {searchMenus.length === 0 && (
              <div className="no-search-result-text">
                <FormattedMessage id="no_search_result" />{' '}
              </div>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default NationSelect;
